import React, { useEffect, useState } from "react";
import iiotAnalytics_bnr from "../../images/product/product_details/iiotAnalyticsBnr.webp";
import iiotanalytics from "../../images/product/product_details/iiotanalytics.webp";
import icon1 from "../../images/product/icons/energy.png";
import icon2 from "../../images/product/icons/asset.png";
import icon3 from "../../images/product/icons/production.png";
import icon4 from "../../images/product/icons/life-cycle.png";
import icon5 from "../../images/product/icons/sync.png";
import { FaDownload, FaEye } from "react-icons/fa6";
import iiotMobileView from "../../images/product/product_details/iiotMobileView.webp";
import catelog1 from "../../pdfCattalog/AssetAccessControl - AAC.pdf";
import catelog2 from "../../pdfCattalog/EquipmentHealthManager - EHM.pdf";
import catelog3 from "../../pdfCattalog/PlantEnergyManager - PEM.pdf";
import catelog4 from "../../pdfCattalog/OperationalEfficiencyManager - OEM.pdf";
import catelog5 from "../../pdfCattalog/SyncMate.pdf";
import DilogueBox from "../../component/DilogueBox";


export const IIOT_AnalyticsDetails = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);
  const [openDownloadModel, setOpenDownloadModel] = useState(false);
  const [pdfData, setPdfData] = useState(false);
  const [isDownload, setIsDownload] = useState(
    localStorage.getItem("is_download")
  );

  // Trigger PDF download
  const downloadPDF = (pdfData) => {
    const link = document.createElement("a");
    link.href = pdfData;
    link.setAttribute("download", "yourfile.pdf"); 
    document.body.appendChild(link);
    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  // Function to open download modal or trigger download based on isDownload state
  const openModel = (value) => {
    console.log(isDownload);
    if (isDownload) {
      downloadPDF(value);
    } else {
      setOpenDownloadModel(!openDownloadModel);
      setPdfData(value);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 500);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bnrImg">
        <img
          src={isMobileView ? iiotMobileView : iiotAnalytics_bnr}
          width="100%"
          height="auto"
        />
      </div>

      <div style={{ backgroundColor: "#f1f1f1" }}>
        <div className="container pt-3">
          <div className="row pt-lg-5">
            <div className="col col-md-12 col-sm-12 col-lg-7 pt-3 pe-lg-4">
              <div className="whatWeDo  pt-4 px-3">
                <h1
                  style={{
                    fontWeight: "600",
                    letterSpacing: "3px",
                  }}
                  className="text-start list-group-item"
                >
                  IIoT & Analytics​
                </h1>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  For an intelligent manufacturing facility, real-time
                  monitoring of machines and processes is vital to optimize
                  production. Achieving peak performance and gaining a clear
                  view of machine health are critical elements for proactively
                  identifying and resolving anomalies.
                </p>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Industrial IoT and Analytics solutions play a pivotal role in
                  empowering factories and discrete manufacturing sectors. They
                  elevate Overall Equipment Effectiveness (OEE) and productivity
                  by providing real-time data visibility, in-depth manufacturing
                  analytics, and predictive/prescriptive alerts.
                </p>
                <p
                  className="card-paragraph mt-4"
                  style={{ textAlign: "justify" }}
                >
                  Airisense’s state-of-the-art technology enhance your
                  production workflows guaranteeing smooth operations and
                  heightened efficiency in manufacturing.
                </p>

                {/* <Link to="/about">
                  {" "}
                  <div className="btn btn-lg btn-borderd button-1 my-3">
                    What We Do
                  </div>{" "}
                </Link> */}
              </div>
            </div>

            <div className="col-md-12 col-sm-12 col-lg-5 mb-5 pt-3 ps-lg-5 ">
              <div className="about_us_img ">
                <img
                  src={iiotanalytics}
                  className="image-fluid "
                  width="100%"
                  alt="productdescription"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "var(--orange)" }}>
        <div className="py-5 container ">
          <div className="row  justify-content-center py-3 ">
            <div className="col-lg-12 ">
              <h1
                style={{
                  fontWeight: "600",
                  letterSpacing: "3px",
                  // fontSize: "55px",
                }}
                className="text-center text-white display-4 "
              >
                Smart Monitoring, Optimal Performance
              </h1>
            </div>

            <div className="py-3">
              <div className="row">
                <div className="col-lg-6  pt-4 pe-lg-2 ">
                  <div className="d-flex">
                    <div className="ps-lg-3">
                      <img src={icon1} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Asset Access Control - AAC{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5  pt-2  ps-lg-3 pe-lg-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    EcoSync-AAC, an innovative software revolutionizing asset
                    management, is designed for dynamic assets such as forklifts
                    and trucks. It provides real-time insights, robust control,
                    and proactive features for optimized performance and
                    accountability.
                  </p>
                  <div className="text-start ms-lg-3 d-flex">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none me-2"
                      onClick={() => openModel(catelog1)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  
                  </div>
                </div>
                <div className="col-lg-6  pt-4 pe-lg-2">
                  <div className="d-flex">
                    <div className="ps-lg-3">
                      <img src={icon2} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Equipment Health Manager - EHM{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5 pt-2  ps-lg-3 pe-lg-5  text-white"
                    style={{ textAlign: "justify" }}
                  >
                    EcoSync-EHM utilizes advanced analytics for real-time
                    monitoring, providing a centralized dashboard for proactive
                    issue identification, automated diagnostics, and enhanced
                    equipment performance.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      onClick={() => openModel(catelog2)}
                    >
                      <span>
                        Download <FaDownload className="me-lg-1 mb-2" />
                      </span>
                    </a>
                    <a
                      className="custom-btn btn-7 ms-2 text-center text-decoration-none"
                      href="https://ecosync-ehm.airi-sense.co.in"
                      link
                      target="_blank"
                    >
                      <span>
                        Demo <FaEye className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-3">
              <div className="row">
                <div className="col-lg-6  pt-4 pe-lg-2 ">
                  <div className="d-flex">
                    <div className="ps-lg-3">
                      <img src={icon3} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Plant Energy Manager - PEM{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5  pt-2  ps-lg-3 pe-lg-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    EcoSync-PEM, our advanced energy management software, is
                    designed for industrial units. This platform excels in
                    recording, analyzing, and optimizing energy consumption,
                    providing real-time visibility, customizable reporting,
                    integration capabilities.
                  </p>
                  <div className="text-start ms-lg-3 d-flex">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      onClick={() => openModel(catelog3)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                    <a
                      className="custom-btn ms-2 btn-7 text-center text-decoration-none"
                      href="https://ecosync-pem.airi-sense.co.in"
                      link
                      target="_blank"
                    >
                      <span>
                        Demo <FaEye className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6  pt-4 pe-lg-2">
                  <div className="d-flex">
                    <div className="ps-lg-3">
                      <img src={icon4} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">
                      Operational Efficiency Manager - OEM{" "}
                    </h4>
                  </div>
                  <p
                    className="fs-5 pt-2  ps-lg-3 pe-lg-5  text-white"
                    style={{ textAlign: "justify" }}
                  >
                    EcoSync-OEM, a robust software solution, is the pinnacle of
                    operational excellence. It records and analyzes production
                    and quality data, offering real-time insights, customizable
                    dashboards, and integration for optimized efficiency.
                  </p>
                  <div className="text-start ms-lg-3">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      onClick={() => openModel(catelog4)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-3">
              <div className="row">
                <div className="col-lg-12  pt-4 pe-lg-2 ">
                  <div className="d-flex">
                    <div className="ps-lg-3">
                      <img src={icon5} className="w-100" />
                    </div>
                    <h4 className="text-white ms-lg-3 pt-2 hori">SyncMate </h4>
                  </div>
                  <p
                    className="fs-5  pt-2  ps-lg-3 pe-lg-5 text-white"
                    style={{ textAlign: "justify" }}
                  >
                    SyncMate seamlessly connects devices for elegant, harmonious
                    digital experiences. Bridging communication gaps
                    effortlessly, it ensures hassle-free data synchronization
                    with industrial-grade communication, visual intelligence,
                    versatile connectivity, and specialized ports for industrial
                    applications. Integrated LEDs offer discreet feedback,
                    enhancing user-friendly high-speed Ethernet connectivity.
                  </p>
                  <div className="text-start ms-lg-3 d-flex">
                    <a
                      className="custom-btn btn-7 text-center text-decoration-none"
                      onClick={() => openModel(catelog5)}
                    >
                      <span>
                        Download <FaDownload className="ms-lg-1 mb-2" />
                      </span>
                    </a>
                    {/* <a
                      className="custom-btn ms-2 btn-7 text-center text-decoration-none"
                      href="http://ecosync-pem-absax.airi-sense.co.in/"
                      link
                      target="_blank"
                    >
                      <span>
                        Demo <FaEye className="ms-lg-1 mb-2" />
                      </span>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        {/* Download modal */}
        <DilogueBox
        open={openDownloadModel}
        setOpen={setOpenDownloadModel}
        pdfData={pdfData}
        setIsDownload={setIsDownload}
      />
    </>
  );
};
